import * as yup from 'yup';

export const validationSchema = yup.object({
  centreId: yup.string().notRequired(),
  _id: yup.string().notRequired(),
  changedPassword: yup.bool().notRequired(),
  name: yup.string('Enter name').required('Name is required'),
  lat: yup.number('Enter lat').required('Lat is required'),
  lng: yup.number('Enter lng').required('Lng is required'),
  address: yup.string('Enter address').required('Address is required'),
  discountPercentage: yup.string('Enter discount percentage').required('Discount Percentage is required'),
  yearOfEstablistment: yup.string('Enter YOE').required('Year of Establishment is required'),
  generalBeds: yup.number('Enter General beds'),
  icuBeds: yup.number('Enter ICU beds'),
  ventilators: yup.number('Enter number of ventilators'),
  emergencyTreatment: yup
    .bool('Is emergency treatment available')
    .required('Select if emergency treatment is available'),
  operationTheatres: yup.number('Enter name'),
  cardiacLab: yup.bool('Is cardiac lab available').required('Select if emergency treatment is available'),
  ambulance: yup.bool('Is ambulance available').required('Select ambulance is available'),
  insurance: yup.bool('Is insurance available').required('Select insurance is available'),
  phoneNumber: yup.string('Enter Phone Number').length(10).required('Phone Number is required'),
  email: yup.string('Enter email').required('Email is required'),
  contactPersonPhoneNumber: yup
    .string('Enter Contact Person Phone Number')
    .length(10)
    .required('Contact Person Phone Number is required'),
  type: yup.string().oneOf(['hospital', 'surgery', 'lab', 'mri_ct_scan', 'health_centre']).required(),
  imagesUrl: yup.array().required(),
  coverImageUrl: yup.string().required('Cover image is required'),
  status: yup.string().notRequired(),
  visible: yup.bool().notRequired(),
});

export const doctorValidationSchema = yup.object({
  name: yup.string('Enter name').required('Name is required'),
  qualifications: yup.string('Enter qualifications').required('Qualification is required'),
  speciality: yup.string('Enter speciality').required('Speciality is required'),
});
