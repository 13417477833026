import { LoadingButton } from '@mui/lab';
import { Container, Stack, Typography, TextField } from '@mui/material';
import AddDoctorForm from '../components/AddDoctorForm';
import Page from '../components/Page';

export default function AddDoctor() {
  return (
    <Page title="Dashboard: Add Hospital">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Add Doctor
        </Typography>
        <AddDoctorForm />
      </Container>
    </Page>
  );
}
