//
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyB1HGdjXUnjuDWcVQyspup5fZQtNM8CZAs',
  authDomain: 'iswaasth-app.firebaseapp.com',
  projectId: 'iswaasth-app',
  storageBucket: 'iswaasth-app.appspot.com',
  messagingSenderId: '449286543058',
  appId: '1:449286543058:web:432148b3f2afa4de3e3f0b',
  measurementId: 'G-Y36RFT1RH6',
};
// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { auth, db, firebase, storage };
