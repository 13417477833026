/* eslint-disable comma-dangle */
import { TextField, Button, Stack, InputAdornment, Switch, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from 'formik';
import {
  deleteMediaFromStorage,
  getAllDoctors,
  getCentreById,
  updateCentreMedia,
  uploadCentre,
  uploadMediaToStorage,
} from '../helpers/helpers';
import { validationSchema } from '../utils/validation';
import { useSnackbar } from 'notistack';
import Loader from './Loader';
import Autocomplete from '@mui/material/Autocomplete';
import { services } from 'src/utils/constants';
import { useNavigate, useParams } from 'react-router-dom';

export default function AddHospitalForm({ isEditing = false }) {
  const params = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCoverImage, setSelectedCoverImage] = useState();
  const [doctors, setDoctors] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      address: '',
      ambulance: false,
      cardiacLab: false,
      centreId: '',
      _id: '',
      name: '',
      lat: '',
      lng: '',
      discountPercentage: '',
      yearOfEstablistment: '',
      generalBeds: 0,
      icuBeds: 0,
      ventilators: 0,
      emergencyTreatment: false,
      operationTheatres: 0,
      insurance: false,
      contactPersonPhoneNumber: '',
      email: '',
      phoneNumber: '',
      type: 'hospital',
      doctors: [],
      services: [],
      coverImageUrl: '',
      imagesUrl: [],
      keywords: '',
      status: '',
      visible: false,
    },
    // validationSchema: validationSchema.notRequired(),
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
      if (!values.coverImageUrl) {
        enqueueSnackbar('Please select cover image', {
          variant: 'error',
        });
        setSubmitting(false);
        return;
      }
      if (values.imagesUrl.length === 0) {
        enqueueSnackbar('Please select images', {
          variant: 'error',
        });
        setSubmitting(false);
        return;
      }
      if (values.services.length === 0) {
        enqueueSnackbar('Please select services', {
          variant: 'error',
        });
        setSubmitting(false);
        return;
      }
      const response = await uploadCentre(values, isEditing);
      setSubmitting(false);
      if (response.error) {
        enqueueSnackbar(`${response.message}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          isEditing ? `${formik.values.name} updated succesfully!` : `${formik.values.name} uploaded succesfully!`,
          { variant: 'success' }
        );
        formik.resetForm();
        setSelectedFiles([]);
        navigate('/dashboard/centres');
      }
    },
  });

  const { isSubmitting, setFieldValue } = formik;

  async function fetchCenterById() {
    const response = await getCentreById(params.id);
    if (response.error) {
      enqueueSnackbar('Failed to fetch your centre detail', {
        variant: 'error',
      });
      setLoading(false);
      return;
    }
    console.log(response.centre);
    formik.setValues(response.centre);
    formik.setFieldValue('lat', response.centre.location.coordinates[0]);
    formik.setFieldValue('lng', response.centre.location.coordinates[1]);
    setLoading(false);
  }

  async function fetchDoctors() {
    const response = await getAllDoctors();
    if (response.error) {
      enqueueSnackbar('Failed to fetch doctors', {
        variant: 'error',
      });
      setLoading(false);
      return;
    }
    setDoctors(response.doctors);
    setLoading(false);
  }

  useEffect(() => {
    fetchDoctors();
    if (isEditing) {
      fetchCenterById();
    }
  }, []);

  return (
    <>
      {loading ? (
        <center>
          <Loader />
        </center>
      ) : (
        <Box onSubmit={formik.handleSubmit} component="form">
          <br />
          <div className="preview-image">
            {formik.values.coverImageUrl && <img src={formik.values.coverImageUrl} alt="" />}
          </div>

          {formik.values.coverImageUrl && (
            <div style={{ paddingTop: '12px' }}>
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  await deleteMediaFromStorage(formik.values.coverImageUrl);
                  if (formik.values.centreId) {
                    await updateCentreMedia(formik.values.centreId, true, '');
                  }
                  setFieldValue('coverImageUrl', '');
                  enqueueSnackbar('Cover image removed successfully', {
                    variant: 'success',
                  });
                }}
                color="error"
                variant="outlined"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          )}
          <br />
          {selectedCoverImage && <LinearProgress />}
          <br />

          <Button fullWidth variant="contained" component="label">
            Upload Cover Image
            <input
              onChange={async (e) => {
                if (formik.values.coverImageUrl) {
                  enqueueSnackbar('Please delete the current cover image and then select a new one.', {
                    variant: 'error',
                  });
                } else {
                  if (e.target.files[0]) {
                    setSelectedCoverImage(e.target.files[0]);
                    const url = await uploadMediaToStorage(e.target.files[0], true);
                    setFieldValue('coverImageUrl', url);
                    if (formik.values.centreId) {
                      await updateCentreMedia(formik.values.centreId, true, url);
                    }
                    setSelectedCoverImage();
                  }
                }
              }}
              type="file"
              hidden
            />
          </Button>
          <br />
          <br />

          <div>
            {formik.values.imagesUrl.length > 0 && (
              <div className="preview-image">
                {formik.values.imagesUrl.map((file, index) => (
                  <div key={index} style={{ flexDirection: 'column' }}>
                    <img src={file} alt="" />
                    <div style={{ paddingTop: '12px' }}>
                      <Button
                        onClick={async (e) => {
                          e.preventDefault();
                          await deleteMediaFromStorage(file);
                          const updatedImages = formik.values.imagesUrl.filter((image) => image !== file);
                          if (formik.values.centreId) {
                            await updateCentreMedia(formik.values.centreId, false, updatedImages);
                          }
                          setFieldValue('imagesUrl', updatedImages);
                          enqueueSnackbar('Image removed successfully', {
                            variant: 'success',
                          });
                        }}
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <br />
            {selectedFiles.length > 0 && (
              <Box>
                <h3>Uploading {selectedFiles.length} images...</h3>
                <LinearProgress />
              </Box>
            )}
            <br />
            <Button fullWidth variant="contained" component="label">
              Upload Image
              <input
                onChange={async (e) => {
                  const files = e.target.files;
                  setSelectedFiles([...files]);
                  const urls = formik.values.imagesUrl;
                  for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const url = await uploadMediaToStorage(file);
                    urls.push(url);
                  }
                  setFieldValue('imagesUrl', urls);
                  setSelectedFiles([]);
                  if (formik.values.centreId) {
                    updateCentreMedia(formik.values.centreId, false, urls);
                  }
                }}
                type="file"
                hidden
                multiple
              />
            </Button>
          </div>
          <br />
          <br />

          <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
          <RadioGroup
            onChange={(e) => {
              setFieldValue('type', e.target.value);
            }}
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="hospital"
            name="radio-buttons-group"
          >
            <FormControlLabel value="hospital" control={<Radio />} label="Hospital" />
            <FormControlLabel value="mri_ct_scan" control={<Radio />} label="MRI/CT Scan" />
            <FormControlLabel value="lab" control={<Radio />} label="Lab" />
            <FormControlLabel value="health_centre" control={<Radio />} label="Health Centre" />
          </RadioGroup>
          <br />
          <TextField
            disabled={isEditing}
            onChange={formik.handleChange}
            value={formik.values.name}
            fullWidth
            autoComplete="username"
            type="text"
            label="Name"
            name="name"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <br />
          <br />
          <Stack flexWrap={'unset'} direction="row">
            <TextField
              onChange={formik.handleChange}
              value={formik.values.email}
              fullWidth
              disabled={isEditing}
              autoComplete="email"
              style={{ marginRight: '12px' }}
              type="email"
              label="Email"
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                isEditing
                  ? 'Email cannot be changed.'
                  : 'Please double check this email. It will be used by the centre to login.'
              }
            />
            <TextField
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              fullWidth
              disabled={isEditing}
              autoComplete="email"
              style={{ marginRight: '12px' }}
              type="number"
              label="Phone Number"
              name="phoneNumber"
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={
                isEditing
                  ? 'Phone number cannot be changed.'
                  : 'Please double check this phone number. It will be used by the centre to login.'
              }
            />{' '}
            <TextField
              onChange={formik.handleChange}
              value={formik.values.contactPersonPhoneNumber}
              fullWidth
              autoComplete="email"
              style={{ marginRight: '12px' }}
              type="number"
              label="Contact Person Phone Number"
              name="contactPersonPhoneNumber"
              error={formik.touched.contactPersonPhoneNumber && Boolean(formik.errors.contactPersonPhoneNumber)}
              helperText={formik.touched.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber}
            />
          </Stack>
          <br />
          <Stack direction="row">
            <TextField
              onChange={formik.handleChange}
              value={formik.values.lat}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Lat"
              name="lat"
              error={formik.touched.lat && Boolean(formik.errors.lat)}
              helperText={formik.touched.lat && formik.errors.lat}
            />
            <TextField
              onChange={formik.handleChange}
              value={formik.values.lng}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Lng"
              name="lng"
              error={formik.touched.lng && Boolean(formik.errors.lng)}
              helperText={formik.touched.lng && formik.errors.lng}
            />
          </Stack>
          <br />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.address}
            fullWidth
            autoComplete="username"
            type="text"
            label="Address"
            name="address"
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
          <br />
          <br />

          <Stack direction="row">
            <TextField
              onChange={formik.handleChange}
              value={formik.values.discountPercentage}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Discount Percentage"
              name="discountPercentage"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={formik.touched.discountPercentage && Boolean(formik.errors.discountPercentage)}
              helperText={formik.touched.discountPercentage && formik.errors.discountPercentage}
            />
            <TextField
              onChange={formik.handleChange}
              value={formik.values.yearOfEstablistment}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Year of Establishment"
              name="yearOfEstablistment"
              error={formik.touched.yearOfEstablistment && Boolean(formik.errors.yearOfEstablistment)}
              helperText={formik.touched.yearOfEstablistment && formik.errors.yearOfEstablistment}
            />
            <TextField
              onChange={formik.handleChange}
              value={formik.values.operationTheatres}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Number of Operation Theatres"
              name="operationTheatres"
              error={formik.touched.operationTheatres && Boolean(formik.errors.operationTheatres)}
              helperText={formik.touched.operationTheatres && formik.errors.operationTheatres}
            />
          </Stack>
          <br />
          <Stack direction="row">
            <TextField
              onChange={formik.handleChange}
              value={formik.values.generalBeds}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Number of General Beds"
              name="generalBeds"
              error={formik.touched.generalBeds && Boolean(formik.errors.generalBeds)}
              helperText={formik.touched.generalBeds && formik.errors.generalBeds}
            />
            <TextField
              onChange={formik.handleChange}
              value={formik.values.icuBeds}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Number of ICU Beds"
              name="icuBeds"
              error={formik.touched.icuBeds && Boolean(formik.errors.icuBeds)}
              helperText={formik.touched.icuBeds && formik.errors.icuBeds}
            />
            <TextField
              onChange={formik.handleChange}
              value={formik.values.ventilators}
              sx="2"
              style={{ marginRight: '15px' }}
              fullWidth
              type="number"
              label="Number of Ventilators"
              name="ventilators"
              error={formik.touched.ventilators && Boolean(formik.errors.ventilators)}
              helperText={formik.touched.ventilators && formik.errors.ventilators}
            />
          </Stack>
          <br />
          <Stack flexWrap={'wrap'} direction="row">
            <FormControlLabel
              name="emergencyTreatment"
              value={formik.values.emergencyTreatment}
              control={
                <Switch
                  checked={formik.values.emergencyTreatment}
                  onChange={(e) => {
                    formik.setFieldValue('emergencyTreatment', e.target.checked);
                  }}
                />
              }
              label="Emergency Treatment Available"
              error={formik.touched.emergencyTreatment && Boolean(formik.errors.emergencyTreatment)}
              helperText={formik.touched.emergencyTreatment && formik.errors.emergencyTreatment}
            />
            <FormControlLabel
              name="cardiacLab"
              value={formik.values.cardiacLab}
              control={
                <Switch
                  checked={formik.values.cardiacLab}
                  onChange={(e) => {
                    formik.setFieldValue('cardiacLab', e.target.checked);
                  }}
                />
              }
              label="Cardiac Lab Available"
              error={formik.touched.cardiacLab && Boolean(formik.errors.cardiacLab)}
              helperText={formik.touched.cardiacLab && formik.errors.cardiacLab}
            />
            <FormControlLabel
              name="ambulance"
              value={formik.values.ambulance}
              control={
                <Switch
                  checked={formik.values.ambulance}
                  onChange={(e) => {
                    formik.setFieldValue('ambulance', e.target.checked);
                  }}
                />
              }
              label="Ambulance Available"
              error={formik.touched.ambulance && Boolean(formik.errors.ambulance)}
              helperText={formik.touched.ambulance && formik.errors.ambulance}
            />
            <FormControlLabel
              name="ambulance"
              value={formik.values.insurance}
              control={
                <Switch
                  checked={formik.values.insurance}
                  onChange={(e) => {
                    formik.setFieldValue('insurance', e.target.checked);
                  }}
                />
              }
              label="Medical Insurance Available"
              error={formik.touched.insurance && Boolean(formik.errors.insurance)}
              helperText={formik.touched.insurance && formik.errors.insurance}
            />
          </Stack>
          <br />
          <Stack direction="row">
            <Autocomplete
              style={{ marginRight: '15px' }}
              sx="2"
              fullWidth
              value={formik.values.doctors}
              name="doctors"
              error={formik.touched.doctors && Boolean(formik.errors.doctors)}
              helperText={formik.touched.doctors && formik.errors.doctors}
              multiple
              id="tags-outlined"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              onChange={(e, values) => {
                setFieldValue('doctors', values);
              }}
              options={doctors}
              getOptionLabel={(option) => option.name}
              selectOnFocus
              renderInput={(params) => <TextField {...params} label="Select Doctors" placeholder="Doctors" />}
            />
            <Autocomplete
              sx="2"
              fullWidth
              value={formik.values.services}
              name="services"
              isOptionEqualToValue={(option, value) => option.value === value.value}
              error={formik.touched.services && Boolean(formik.errors.services)}
              helperText={formik.touched.services && formik.errors.services}
              multiple
              id="tags-outlined"
              onChange={(e, values) => {
                setFieldValue('services', values);
              }}
              options={services}
              getOptionLabel={(option) => option.service}
              selectOnFocus
              renderInput={(params) => <TextField {...params} label="Select Services" placeholder="Service" />}
            />
          </Stack>
          <br />
          <br />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Box>
      )}
    </>
  );
}
