// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Add Hospital/Labs',
    path: '/dashboard/create',
    icon: getIcon('eva:plus-square-outline'),
  },
  {
    title: 'Add Doctors',
    path: '/dashboard/add-doctor',
    icon: getIcon('eva:person-add-fill'),
  },
  {
    title: 'Centres',
    path: '/dashboard/centres',
    icon: getIcon('ic:round-list'),
  },
  {
    title: 'Offers Requests',
    path: '/dashboard/offers-requests',
    icon: getIcon('bxs:offer'),
  },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
