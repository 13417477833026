import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Input,
  TextField,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserListHead, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import { getAllOffers, updateOfferStatus } from 'src/helpers/helpers';
import Loader from 'src/components/Loader';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import OfferMoreMenu from 'src/sections/@dashboard/offer/OfferMoreMenu';
import { LoadingButton } from '@mui/lab';
import { auth } from 'src/firebase';
// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'startTime', label: 'Start Time', alignRight: false },
  { id: 'endTime', label: 'End Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

export default function OffersRequests() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [offers, setOffers] = useState([]);

  const [loading, setLoading] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const [reasonForRejection, setReasonForRejection] = useState('');

  const [selectedOfferId, setSelectedOfferId] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const fetchOffers = async () => {
    const response = await getAllOffers('waiting');
    setLoading(false);
    console.log(response);
    if (response === 'error') {
      enqueueSnackbar('Failed to fetch offers', {
        variant: 'error',
      });
      return;
    }
    setOffers(response.offers);
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  const submitOfferAction = async (status, offerId) => {
    if (offerId) {
      const payload = {
        reasonForRejection,
        status,
        id: offerId,
      };
      if (status === 'approved') {
        delete payload.reasonForRejection;
      }
      const response = await updateOfferStatus(payload);
      if (response === 'error') {
        enqueueSnackbar('Failed to update offer status. Please try again.', { variant: 'error' });
      } else {
        enqueueSnackbar('Offer updated successfully', { variant: 'success' });
        fetchOffers();
      }
    } else {
      enqueueSnackbar('Offer ID missing.', { variant: 'error' });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    //
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - offers.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Offers Requests">
      {loading ? (
        <center>
          <Loader />
        </center>
      ) : (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack>
              <Typography variant="h4" gutterBottom>
                Offers Requests
              </Typography>
              <Typography variant="p2" gutterBottom>
                Offers which are in waiting status will only show up here.
              </Typography>
            </Stack>
          </Stack>

          <Card>
            {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {offers.map((offer) => {
                      const { offerId, title, startTime, endTime, offerImageUrl, status } = offer;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      const expired = dayjs(new Date()).isAfter(endTime);

                      return (
                        <TableRow
                          hover
                          key={offerId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} /> */}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={title} src={offerImageUrl} />
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{dayjs(startTime).format('MMMM D, YYYY h:mm A')}</TableCell>
                          <TableCell align="left">{dayjs(endTime).format('MMMM D, YYYY h:mm A')}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={status === 'waiting' ? 'warning' : status === 'rejected' ? 'error' : 'success'}
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          {/* <TableCell align="left">
                            <Label variant="ghost" color={expired ? 'error' : 'success'}>
                              {expired ? 'EXPIRED' : 'ACTIVE'}
                            </Label>
                          </TableCell> */}

                          <TableCell align="right">
                            <OfferMoreMenu
                              handleOfferAction={(action) => {
                                if (action === 'approved') {
                                  submitOfferAction('approved', offerId);
                                } else {
                                  setSelectedOfferId(offerId);
                                  setOpenModal(true);
                                }
                              }}
                              centreId={offerId}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {/* {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )} */}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...modalStyle, width: 600 }}>
          <Typography sx={{ mb: 2 }} variant="h4">
            Enter reason for offer rejection:
          </Typography>

          <TextField
            onChange={(e) => setReasonForRejection(e.target.value)}
            multiline
            minRows={3}
            value={reasonForRejection}
            placeholder="Enter reason here"
            fullWidth
          />
          <LoadingButton
            onClick={(e) => {
              e.preventDefault();
              submitOfferAction('rejected', selectedOfferId);
              setOpenModal(false);
            }}
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
          >
            Submit
          </LoadingButton>
        </Box>
      </Modal>
    </Page>
  );
}
