/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
import { auth, storage } from '../firebase';
import { API } from '../utils/constants';

const store = storage.ref();

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export const uploadMediaToStorage = async (file, isCoverImage = false) => {
  const response = await store.child(isCoverImage ? `cover_${file.name}` : file.name).put(file);
  return await response.ref.getDownloadURL();
};

export const deleteMediaFromStorage = async (url) => {
  try {
    const ref = store.storage.refFromURL(url);
    await ref.delete(ref.fullPath);
  } catch (error) {
    console.log(error);
  }
};

export const uploadDoctor = async (doctor) => {
  const token = await auth.currentUser.getIdToken();
  const profileImageUrl = await uploadMediaToStorage(doctor.profileImage);
  doctor.profileImageUrl = profileImageUrl;
  try {
    return fetch(`${API}/doctor/upload-doctor`, {
      method: 'POST',
      body: JSON.stringify(doctor),
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(err);
        return 'error';
      });
  } catch (e) {
    console.log(e);
    return 'error';
  }
};

export const uploadCentre = async (details, isEditing) => {
  try {
    const token = await auth.currentUser.getIdToken();
    const endpoint = isEditing ? `${API}/centre/${details.centreId}` : `${API}/centre/upload-centre`;
    return fetch(endpoint, {
      method: isEditing ? 'PATCH' : 'POST',
      body: JSON.stringify(details),
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    return 'error';
  }
};

export const getAllCentres = async () => {
  const token = await auth.currentUser.getIdToken();
  return fetch(`${API}/centre/all`, {
    method: 'GET',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log(err);
      return 'error';
    });
};

export const getCentreById = async (centreId) => {
  const token = await auth.currentUser.getIdToken();
  return fetch(`${API}/centre/${centreId}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log(err);
      return 'error';
    });
};

export const getAllDoctors = async () => {
  const token = await auth.currentUser.getIdToken();
  return fetch(`${API}/doctor/all`, {
    method: 'GET',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log(err);
      return 'error';
    });
};

export const updateCentreMedia = async (centreId, updatingCover, data) => {
  const token = await auth.currentUser.getIdToken();
  const payload = { centreId };
  if (updatingCover) {
    payload.coverImageUrl = data;
  } else {
    payload.imagesUrl = data;
  }
  console.log(payload);
  try {
    return fetch(updatingCover ? `${API}/centre/media/cover-image` : `${API}/centre/media/images`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(err);
        return 'error';
      });
  } catch (e) {
    console.log(e);
    return 'error';
  }
};

export const getAllOffers = async (status) => {
  const token = await auth.currentUser.getIdToken();
  const endpoint = status ? `${API}/offers/all?status=${status}` : `${API}/offers/all`;
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      Authorization: token,
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log(err);
      return 'error';
    });
};

export const updateOfferStatus = async (offer) => {
  try {
    const token = await auth.currentUser.getIdToken();
    console.log(offer);
    return fetch(`${API}/offers/update-status`, {
      method: 'POST',
      body: JSON.stringify(offer),
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(err);
        return 'error';
      });
  } catch (e) {
    return 'error';
  }
};
