import { Container, Typography, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Router, useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import AddHospitalForm from '../components/AddHospitalForm';
import Page from '../components/Page';

export default function AddHospital({ isEditing = false }) {
  const navigate = useNavigate();

  return (
    <Page title="Dashboard: Add Hospital">
      <Container>
        <Box flexDirection={'row'} display="flex" alignItems={'center'}>
          {isEditing && (
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon={'fluent-mdl2:back'} />
            </IconButton>
          )}
          <Typography variant="h4" sx={{ ml: 2 }}>
            {isEditing ? 'Edit Hospital' : 'Add Hospital'}
          </Typography>
        </Box>
        <AddHospitalForm isEditing={isEditing} />
      </Container>
    </Page>
  );
}
