export const API =
  process.env.NODE_ENV === 'production' ? 'https://iswaasth-app.uc.r.appspot.com' : 'http://localhost:3001';
export const services = [
  { service: 'Consult a doctor', value: 'Consult_a_doctor' },
  { service: 'Surgery', value: 'Surgery' },
  { service: 'Lab Test', value: 'Lab_Test' },
  { service: 'MRI CT Scan', value: 'MRI_CT_Scan' },
  { service: 'Hospital Admission', value: 'Hospital_Admission' },
  { service: 'CT Scan', value: 'CT_Scan' },
  { service: 'MRI Scan', value: 'MRI_Scan' },
  { service: 'Ultrasound', value: 'Ultrasound' },
  { service: 'Pet Scan', value: 'Pet_Scan' },
  { service: 'Digital X-Ray', value: 'Digital_X_Ray' },
  { service: 'ECG', value: 'ECG' },
  { service: '2D Echo', value: '2D_Echo' },
  { service: 'TMT Stress Test', value: 'TMT_Stress_Test' },
  { service: 'EEG', value: 'EEG' },
  { service: 'EMG', value: 'EMG' },
  { service: 'MAMMOGRAPHY', value: 'MAMMOGRAPHY' },
  { service: 'PFT', value: 'PFT' },
];
