import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import { auth } from '../firebase';
//
import { Link as RouterLink, useNavigate, useRoutes } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
export const AuthContext = React.createContext();
export const Reloader = React.createContext();

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [blockAccess, setBlockAccess] = useState(false);
  const [pending, setPending] = useState(true);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  const toggleReload = () => {
    setReload((prevReload) => !prevReload);
  };

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const result = await user.getIdTokenResult();
        if (result.claims.role !== 'admin') {
          setBlockAccess(true);
        }
      }
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <center style={{ paddingTop: '14rem' }}>
        <div className="container">
          <img height="200px" width="200px" src={`${process.env.PUBLIC_URL}/static/logo.png`} alt="" />
          <p id="loading">Loading...</p>
        </div>
      </center>
    );
  }

  if (blockAccess) {
    return (
      <Page title="404 Page Not Found">
        <Container>
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h3" paragraph>
              Sorry, you do not have admin access to use this portal.
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Please contact iswaasth to grant you the access to use this portal.
            </Typography>

            <Box
              component="img"
              src="/static/illustrations/illustration_404.svg"
              sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />

            <Button
              onClick={async (e) => {
                e.preventDefault();
                await auth.signOut();
                location.href = '/login';
              }}
              size="large"
              variant="contained"
            >
              Logout
            </Button>
          </ContentStyle>
        </Container>
      </Page>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        reload,
      }}
    >
      <Reloader.Provider value={toggleReload}>{children}</Reloader.Provider>
    </AuthContext.Provider>
  );
};
