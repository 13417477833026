import * as Yup from 'yup';

import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { auth } from '../../../firebase';

// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [password, setpassword] = useState();
  const [error, setError] = useState();
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result.user);
        navigate('/dashboard/centres', { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
        setLoading(false);
      });
  };

  // const LoginSchema = Yup.object().shape({
  //   email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  //   password: Yup.string().required('Password is required'),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     email: '',
  //     password: '',
  //     remember: true,
  //   },
  //   validationSchema: LoginSchema,
  //   onSubmit: () => {
  //     //  navigate('/dashboard', { replace: true });
  //   },
  // });

  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <br />
      <Stack spacing={3}>
        <TextField
          onChange={(e) => {
            setErrors({ ...errors, email: false });
            setEmail(e.target.value);
          }}
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          helperText={errors.email && 'Email address is required'}
          error={errors.email}
        />

        <TextField
          onChange={(e) => {
            setpassword(e.target.value);
          }}
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          helperText={errors.password && 'Password is required'}
          error={errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link>
        </Stack> */}
      <LoadingButton onClick={handleSubmit} fullWidth size="large" type="button" variant="contained" loading={loading}>
        Login
      </LoadingButton>
    </>
  );
}
